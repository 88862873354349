/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    $( document ).ready( function() {

        /*
         * Sticky Header on scroll
         */
        if(!$('body').hasClass('single-post')) {

            window.addEventListener('scroll', function(e){
                var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                    shrinkOn = 20,
                    header = $('#js-header');
                if (distanceY > shrinkOn) {
                    header.addClass('site-head--alt');
                } else {
                    if (header.hasClass('site-head--alt')) {
                        header.removeClass('site-head--alt');
                    }
                }
            });
        }


        /*
         * Article load down
         */
        if($('body').hasClass('single-post')) {
            header = $('#js-header');
            $('html,body').scrollTop(header.outerHeight());
        }


        /*
         * Sticky Post Header on scroll
         */
        if($('#js-postSticky').length) {
            var bannerSize = $('#js-postHeader').outerHeight();
            var postHeader = $('#js-postSticky');

            window.addEventListener('scroll', function(e){
                var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                    shrinkOn = bannerSize;
                if (distanceY > shrinkOn) {
                    postHeader.removeClass('hidden');
                    //postHeader.addClass('animated  fadeIn');
                } else {
                    postHeader.addClass('hidden');
                    //postHeader.removeClass('animated  fadeIn');
                }
            });
        }



        /*
         * Smooth Scroll
         * https://css-tricks.com/snippets/jquery/smooth-scrolling/
         */
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top - 113
                }, 1000);
                return false;
              }
            }
          });


        /*
         * Mobile Nav
         */
        $('.js-menuToggle').on('click', function(e){
            e.preventDefault();
            $('.js-menuToggle').toggleClass('burger--open');
            $('.nav-primary__menu').toggleClass('nav-primary__menu--open');

            $('.site-head').toggleClass('site-head--mobile-open');
        });

        function closeMenu() {
            $('.js-menuToggle').removeClass('burger--open');
            $('.nav-primary__menu').removeClass('nav-primary__menu--open');
        }

        $(window).resize(function(){
            closeMenu();
        });



        // lightbox
        $('a.js-lightbox-toggle, li.js-lightbox-open > a').on('click', function(evt) {
          evt.preventDefault();

          var lightbox = $(this).data('lightbox');
          $(lightbox).toggleClass('isHidden');
        });

        if($('#lightboxDonate').length && localStorage.getItem('lightboxDonateState') !== '1'){
            setTimeout(function() {
                $('#lightboxDonate').removeClass('isHidden');
                localStorage.setItem('lightboxDonateState', 1);
            }, 1000);
        }
    });

})(jQuery); // Fully reference jQuery after this point.
